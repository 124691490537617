import request from "@http";

// 获取小区列表
export function getBaseInfoCommunityApi(params) {
    return request({
        method: "GET",
        url: "/api/gzf/baseinfo/community/",
        params,
    });
}
